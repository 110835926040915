* {
  margin: 0;
  padding: 0;
}

$top-background: #FFFFFF;
$bottom-background: #000066; 

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Permanent+Marker&display=swap');
@import "font.css";



body {
  background-color: $top-background;
  font-family: "JetBrains Mono", monospace;
}


span.center {
  display:table;
  margin:0 auto;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

ul.c-social {

  text-align: center !important;
  font-size: 1.625rem;
  padding-left: 0;
}

.map-pin {
  color: $bottom-background;
  text-align: center !important;
  font-size: 1rem;
}

ul.c-social li{
  display: inline;
  padding: 1em;
}

a {
  text-decoration: none !important;
  display: inline-block;
}

img {
  max-width: 100%;
}

button:focus {
  outline: none;
}

p {
  font-size: 14px;
  line-height: 26px;
  margin: 0;
}

.titlecard{
  color: $bottom-background;
  font-size: 60px;
  font-family: CustomFont;
  margin: 1em;
  text-align: center;

}


// FOR THE FLOATING HEADER
/*CUSTOM VARIABLES HERE*/
header {
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  position:fixed;
  z-index: 1;
  background: $top-background;
  padding: 1rem;
  color: $bottom-background;
  text-align: center;
  font-family: CustomFont;


  body.header-not-at-top & {
    transition: visibility 0s, opacity 0.5s ease-in;
    visibility: visible;
    opacity: 1;
    max-height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  @at-root {
    body.header-not-at-top {
      padding-top: 44px;
      top: 0;
      bottom: 0;
      li{
        padding: .5em;
      }
      ul.c-social{
        margin: 0;
        padding-inline-end: 20px;
      }
    }
  }
}

#top-of-site-pixel-anchor {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 750px;
  left: 0;
}

//END OF HEADER NONSENSE


@media all and (max-width: 750px) {
  .titlecard { font-size: 65px;
    margin: 0.5em;
  }
  .header{
    padding: 0;
    margin: 0;
  }

} //mobile devices

@media all and (max-width: 400px) {
  .titlecard { font-size: 58px;
    margin: 0.5em;
  }
} //smolller mobile devices


.about-me {
  color: $bottom-background;
  font-size: 1em;
  font-family: "JetBrains Mono", monospace;
  margin: 1ex;
  padding-bottom: 35px;
  text-align: center;
}

img.circle {
  border-radius: 100%;
  box-shadow: 1px 1px 10px;
  display: block;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

img.center{
  border-radius: 100%;
  box-shadow: 1px 1px 10px;
  display: block;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  -webkit-filter: grayscale(100%);
  transition: filter .25s ease-in-out;
}

.leftmargin{
  margin-left: 5px;
}

img.center:hover{
  -webkit-filter: grayscale(0%);
}

.container {
  width: 100%;
  max-width: 2200px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;
}

.timelines h2 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 32px;
}

.d-flex-2 {
  display: flex;
  align-items: center;
}

.d-flex-image-text {
  display: flex;
  align-items: center;
}

.d-flex-2:hover .t-square {
  animation: square-to-circle .5s 1 cubic-bezier(1, .015, .295, 1.225);
  border-radius: 100% 100% 100% 100%;
  background: $top-background;
}

.timeline-area {
  padding: 100px 0;
  background: $bottom-background;
  box-shadow: 1px 1px 50px $bottom-background;
}

.all-timelines {
  position: relative;
}

.timelines h2 {
  text-align: center;
  color: $top-background;
  font-weight: 600;
  margin-bottom: 40px;
}

.timeline-area li {
    display: list-item;
   padding: 0.5em;
}

.all-timelines::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 2px;
  background: #75c23f;
  top: 20px;
}

.single-timeline {
  margin-bottom: 22px;
}

.timeline-blank {
  width: 50%;
}

.timeline-text {
  width: 50%;
  padding-left: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.timeline-text h6 {
  color: $top-background;
  font-weight: 900;
  display: inline-block;
  font-size: 1rem;
}

.timeline-text span {
  color: $top-background;
  display: block;
  width: 100%;
}


.timeline-text-title {
  float: right;
  width: 50%;
  text-align: right;
  padding-left: 0;
  padding-right: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.single-timeline:nth-child(odd) .timeline-text-title{
  float: left;
  width: 50%;
  text-align: left;
  padding-left: 30px;
  padding-right: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}



.timeline-text-title h6 {
  color: $top-background;
  font-weight: 900;
  display: inline-block;
  font-size: 1rem;
}

.t-square {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: -6px;
  background: #75c23f;
  animation: circle-to-square .5s 1;
  transition: background 0.3s ease-in-out;
}

// Timeline entry (even)
.single-timeline:nth-child(odd) .timeline-text span {
  text-align: left;
  padding-left: 1em;
}

.single-timeline:nth-child(odd) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.single-timeline:nth-child(odd) .t-square {
  right: -6px;
  left: unset;
}

.single-timeline:nth-child(odd) .timeline-text {
  padding-left: 0;
  padding-right: 30px;
  text-align: right;

}

// Animation keyframes for the timeline markers
@keyframes square-to-circle {
  0% {
    border-radius: 0 0 0 0;
    background: #75c23f;
    transform: rotate(0deg);
  }
  100% {
    border-radius: 100% 100% 100% 100%;
    background: $top-background;
    transform: rotate(360deg);
  }
}

@keyframes circle-to-square {
  0% {
    border-radius: 100% 100% 100% 100%;
    background: $top-background;
    transform: rotate(360deg);
  }
  100% {
    border-radius: 0 0 0 0;
    background: #75c23f;
    transform: rotate(0deg);
  }
}

a {
  color: #cc0000;
}

@media all and (max-width: 768px) {


  .all-timelines::before {
    right: unset;
    top: 0;
  }
  .single-timeline:nth-child(2n+1) .timeline-text {
    padding-left: 30px;
    padding-right: 0;
    text-align: left;
  }

  .single-timeline:nth-child(2n) .timeline-text .d-flex-image-text {
    flex-direction: column-reverse;

  }
  .single-timeline .timeline-text  {
    flex-direction: column;

  }

  .single-timeline .timeline-text .d-flex-image-text img{
   padding-bottom: 1rem;


  }
  .single-timeline:nth-child(2n+1) .timeline-text .d-flex-image-text {
    flex-direction: column;

  }

    .single-timeline:nth-child(2n+1) .t-square {
      top: -1.7em;
    left: -5px;
    right: unset;
    }

  .single-timeline:nth-child(2n) .t-square {
    top: -1.7em;
    left: -5px;
    right: unset;
  }
  .timeline-blank{
    width: 100%;
  }
  .single-timeline {
   flex-direction: column;
  }
  .single-timeline:nth-child(odd) {
    flex-direction: column;
  }

  .timeline-text {
    width: 100%;
  }
  .timeline-text-title{
    text-align: left;
    margin: 0 auto;
    padding-left: 30px;
    float: left;
    padding-right: 0px;
    padding-bottom: 10px;
  }
  .single-timeline:nth-child(odd) .timeline-text-title{
    float: left;
  }
  .single-timeline:nth-child(odd) .timeline-text span {
    padding-left: 0;
  }

  .no-padding {
    padding-bottom: 0 !important;
  }


}
@media all and (max-width: 360px) {
  .all-timelines::before {
    top: 32px;
  }

  .single-timeline:nth-child(2n+1) .t-square {
    top: 0;

  }

  .single-timeline:nth-child(2n) .t-square {
    top: 0;
  }
}

